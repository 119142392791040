<script setup lang="ts">

const supabase = useSupabaseClient()
////DATA
const CongoRiverImg = ref('')

////METHODS
const loadPic = async () => {
    CongoRiverImg.value = await supabase.storage.from('Pics').getPublicUrl('Congo_River.jpg').data.publicUrl
}

onBeforeMount(() => {
    loadPic()
})

</script>

<template>
    <main class="flex flex-col justify-center items-center min-h-screen"
        :style="{ backgroundImage: `url(${CongoRiverImg})` }">
        <slot />
    </main>
</template>
